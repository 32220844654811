import React, {lazy, Suspense} from 'react';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router' // react-router v4/v5
import {ConnectedRouter} from 'connected-react-router'
import createStore, {history} from '../redux/create';
import combineReducers from '../reducers';

import config from '../config';
import AppContainer from './AppContainer';
import {SnackbarProvider} from "notistack";
import Button from "@material-ui/core/Button";
import {
  ADD_ADMIN_PAGE,
  ADMIN_PROFILE_PAGE,
  AGENT_DETAILS_PAGE,
  CONFIGURE_OTP_PAGE,
  DASHBOARD_PAGE, DashboardViews, EXE_PENDING_PAGE, EXTENSION_ADMINISTRATION_PAGE,
  FORGOT_PASSWORD_PAGE, HELP_MITIGATIONS_PAGE,
  LOGIN_PAGE, PID_DETAILS_PAGE,
  PRODUCT_TYPE_ENTERPRISE,
  REGISTER_PAGE
} from "../constants";
import {PulseSpinner,} from "react-spinners-kit";
import PidDetails from "../pages/PidDetailsPage/PidDetails";

const ENV_PRODUCT_TYPE = process.env.REACT_APP_PRODUCT_TYPE;
const store = createStore(combineReducers);
export const fallback = <div
  style={{
    display: 'flex',
    minHeight: '100vh',
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: config.palette.primary.main
  }}
>
  <PulseSpinner size={80} color={config.palette.primary.light} loading={true}/>
</div>
const App = () => {
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  if (navigator.userAgent.indexOf("MSIE 9.0") > 0) {
    document.documentElement.className += " k-no-flexbox";
  }
  const notistackRef = React.createRef();
  document.title = config.title;
  const Dashboard = lazy(() => import('../pages/Dashboard/EnterpriseDashboard'));
  const AgentDetailsPage = lazy(() => import('../pages/AgentDetailsPage/AgentDetails'));
  //const Dashboard = ENV_PRODUCT_TYPE === PRODUCT_TYPE_ENTERPRISE ? EnterpriseDashboard : BasicDashboard;
  const Login = lazy(() => import('../pages/Login/Login'));
  const Register = lazy(() => import('../pages/Register'));
  const AddAdmin = lazy(() => import('../pages/AddAdmin'));
  const AdminProfile = lazy(() => import('../pages/AdminProfile'));
  const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
  const ConfigureOTP = lazy(() => import('../pages/ConfigureOTP'));

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SnackbarProvider maxSnack={4}
                          ref={notistackRef}
                          action={(key) => (
                              <Button onClick={onClickDismiss(key)} style={{color: 'white'}}>
                                Dismiss
                              </Button>
                          )}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}>
          <AppContainer>
            <Suspense
              fallback={fallback}>
              <Switch>
                <Route exact path={`/${DASHBOARD_PAGE}`}
                       component={() => <Dashboard view={DashboardViews.dashboard}/>}/>
                <Route exact path={`/${EXTENSION_ADMINISTRATION_PAGE}`}
                       component={() => <Dashboard view={DashboardViews.extensionAdministration}/>}/>
                <Route exact path={`/${EXE_PENDING_PAGE}`}
                       component={() => <Dashboard view={DashboardViews.pendingApproval}/>}/>
                <Route exact path={`/${HELP_MITIGATIONS_PAGE}`}
                       component={() => <Dashboard view={DashboardViews.mitigations}/>}/>
                <Route exact path={`/${LOGIN_PAGE}`} component={Login}/>
                <Route exact path={`/${REGISTER_PAGE}`} component={Register}/>
                <Route exact path={`/${ADD_ADMIN_PAGE}`} component={AddAdmin}/>
                <Route exact path={`/${ADMIN_PROFILE_PAGE}`} component={AdminProfile}/>
                <Route exact path={`/${FORGOT_PASSWORD_PAGE}`} component={ForgotPassword}/>
                <Route exact path={`/${CONFIGURE_OTP_PAGE}`} component={ConfigureOTP}/>
                <Route path={`/${AGENT_DETAILS_PAGE}/:agentId`} component={AgentDetailsPage}/>
                <Route path={`/${PID_DETAILS_PAGE}/:agentId/:PID`} component={PidDetails}/>
                <Route render={() => (<Login/>)}/>
              </Switch>
            </Suspense>
          </AppContainer>
        </SnackbarProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
