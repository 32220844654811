/**
 * Created by radughitescu on 13/01/2020
 */

import {createMuiTheme} from "@material-ui/core";
import config from "../../config";

export const drawerWidth = 256;
export let theme = createMuiTheme({
  typography: {
    h5: {
      fontWeight: 500, fontSize: 26, letterSpacing: 0.5
    }
  }, palette: {
    primary: {
      light: config.palette.primary.light, main: config.palette.primary.main, dark: config.palette.primary.dark
    }, secondary: {
      light: config.palette.primary.dark, main: config.palette.primary.main
    }
  }, shape: {
    borderRadius: 8
  }
});
theme = {
  ...theme, overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: config.palette.muiDrawer
      }
    }, MuiButton: {
      label: {
        textTransform: 'none'
      }, contained: {
        boxShadow: 'none', '&:active': {
          boxShadow: 'none'
        }
      }
    }, MuiTabs: {
      root: {
        marginLeft: theme.spacing(1)
      }, indicator: {
        height: 3, borderTopLeftRadius: 3, borderTopRightRadius: 3, backgroundColor: theme.palette.common.white
      }
    }, MuiTab: {
      root: {
        textTransform: 'none', margin: '0 16px', minWidth: 0, padding: 0, [theme.breakpoints.up('md')]: {
          padding: 0, minWidth: 0
        }
      }
    }, MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    }, MuiDivider: {
      root: {
        backgroundColor: config.palette.muiDivider
      }
    }, MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium
      }
    }, MuiListItemIcon: {
      root: {
        color: 'inherit', marginRight: 0, '& svg': {
          fontSize: 20
        }
      }
    }, MuiAvatar: {
      root: {
        width: 32, height: 32
      }
    }, MuiTableCell: {
      root: {
        backgroundColor: config.palette.primary.main,
        borderBottomColor: 'transparent',
        color: '#fff'
      },
      body: {
        backgroundColor: config.palette.primary.main,
        borderBottomColor: 'transparent',
        color: '#fff'
      },
    },
    '& .MTableHeader-header': {
      backgroundColor: config.palette.primary.main,
    },
    MuiTableHead: {
      root: {
        backgroundColor: config.palette.primary.main,
      },
      body: {
        backgroundColor: config.palette.primary.main,
      }
    },
    MTableHeader: {
      root: {
        backgroundColor: config.palette.primary.main,
      },
      header: {
        backgroundColor: config.palette.primary.main,
      }
    }, MuiIconButton: {
      root: {
        padding: theme.spacing(1), color: "#FFFFFF !important",
      }, disabled: {
        color: '#FFFFFF',
      }
    }, MuiButtonBase: {
      root: {
        color: "#FFFFFF",
      }, disabled: {
        color: '#FFFFFF',
      }
    }, MuiTypography: {
      root: {
        color: '#FFFFFF',
      }
    }, MuiPaper: {
      root: {
        backgroundColor: config.palette.primary.main,
      }
    },
    MTableToolbar:{
      highlighted:{
        backgroundColor: config.palette.primary.main,
      }
    },
    MuiInputBase: {
      root: {
        color: '#FFFFFF',
      }
    },
  }, props: {
    MuiTab: {
      disableRipple: true
    }
  }, mixins: {
    ...theme.mixins, toolbar: {
      minHeight: 48
    }
  }
};
export const styles = {
  root: {
    display: 'flex', minHeight: '100vh'
  }, drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth, flexShrink: 0
    }
  }, appContent: {
    flex: 1, display: 'flex', flexDirection: 'column'
  }, mainContent: {
    flex: 1, background: config.palette.primary.dark, padding: 50,
  }, submit: {
    marginTop: theme.spacing(2), backgroundColor: config.palette.primary.main, '&:hover': {
      backgroundColor: config.palette.secondary.dark
    }
  }, inputField: {
    marginTop: theme.spacing(2), '& label.Mui-focused': {
      color: config.palette.primary.main
    }, '& .MuiInput-underline:after': {
      borderBottomColor: config.palette.primary.main
    }, '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: config.palette.primary.main
      }, '&.Mui-focused fieldset': {
        borderColor: config.palette.primary.main
      }
    }
  }, terminalContainer: {
    overflowY: 'hidden'
  }, terminalInput: {
    backgroundColor: `${config.palette.primary.main} !important`, '& input': {height: '28px !important'}
  }, fab: {
    position: 'fixed', bottom: theme.spacing(2), right: theme.spacing(2), margin: theme.spacing(1), zIndex: '10',
  }, extendedIcon: {
    marginRight: theme.spacing(1)
  }, minimizeIcon: {
    marginTop: '-5px'
  }, minimizeIconContainer: {
    marginRight: theme.spacing(1), float: 'right'
  }, cliContainer: {}
};
