/**
 * Created by radughitescu on 05.11.2023
 */

import useSWR from "swr";
import config from "../../../config";
import {fetcher, mutator} from "../../../components/Scopes/api/fetchers";

export const useGetPidDetails = ({agentId, PID}) => {
  const {data, error, isLoading} = useSWR(`${config.apiURL}/api/getPID`,
    url => mutator(url, {arg: {agentID: agentId, PID: PID}}))
  return {
    data, error, isLoading
  }
}
