/**
 * Created by radughitescu on 12/07/2019
 */

import {applyMiddleware, createStore as _createStore} from 'redux';
import ReduxThunk from 'redux-thunk';
import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory, createHashHistory} from 'history'
import createMiddleware from './middleware/client-middleware';
import reducer from './../reducers';
import checkAuthMiddleware from "./middleware/checkAuthMiddleware";
// import { supportsHistory } from 'history/lib/DOMUtils';
const supportsHistory = 'pushState' in window.history;

export const history = supportsHistory ? createBrowserHistory() : createHashHistory();

// const createHistory = supportsHistory() ? createBrowserHistory : createHashHistory;

export default function createStore(client, data) {
  const middleware = [createMiddleware(client), checkAuthMiddleware(client, history), ReduxThunk, routerMiddleware(
    history)];

  const store = _createStore(reducer(history), applyMiddleware(...middleware));

  return store;
}
