/**
 * Created by radughitescu on 05.11.2023
 */

import {getAuthHeaders} from "../../../utils/getAuthHeaders";

export const fetcher = (...args) => fetch(...args, {headers: {...getAuthHeaders()}, cache:'no-cache'}).then(res => res.json())

export const fetcherPost = (...args) => fetch(...args, {method: 'POST', headers: {...getAuthHeaders()}})
  .then(res => res.json())


export async function mutator(url, {arg}) {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(arg),
    headers: {
      ...getAuthHeaders()
    }
  }).then(res => res.json())
}
