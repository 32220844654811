/**
 * Created by radughitescu on 12/07/2019
 */

import keyMirror from '../utils/keymirror';

export const ERROR = keyMirror('error', {
  error: null,
  close: null
});

export const AUTH = keyMirror('AUTH', {
  login: null,
  loginSuccess: null,
  loginFail: null
});

export const NETWORKOVERVIEW = keyMirror('NETOVERVIEW', {
  getTableData: null,
  getTableDataSuccess: null,
  getTableDataFail: null
});

export const DashboardViews = {
  dashboard: 'dashboard',
  extensionAdministration: 'addStorage',
  pendingApproval: 'pendingApproval',
  mitigations: 'mitigations',
}

//pages
export const DASHBOARD_PAGE = 'dashboard';
export const EXTENSION_ADMINISTRATION_PAGE = 'extension-administration';
export const EXE_PENDING_PAGE = 'pending-approval';
export const HELP_MITIGATIONS_PAGE = 'help-mitigations';
export const ADD_ADMIN_PAGE = 'addAdmin';
export const LOGIN_PAGE = 'login';
export const REGISTER_PAGE = 'register';
export const REGISTER_PAGE_STEP2 = 'register?step=2';
export const REGISTER_PAGE_STEP3 = 'register?step=3';
export const ADMIN_PROFILE_PAGE = 'admin-profile';
export const FORGOT_PASSWORD_PAGE = 'forgot-password';
export const CONFIGURE_OTP_PAGE = 'configure-otp';
export const AGENT_DETAILS_PAGE = 'agent-details';
export const PID_DETAILS_PAGE = 'pid-details';
export const PRODUCT_TYPE_BASIC = 'basic_product';
export const PRODUCT_TYPE_ENTERPRISE = 'enterprise_product';

// rest api responses
export const NEEDS_OTP = 'otp';
export const BAD_OTP = 'bad-otp';
export const OTP_CONFIGURE = 'otp-configure';
export const SUCCESS_LOGIN = 'successful-login';
