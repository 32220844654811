/**
 * Created by radughitescu on 13/01/2020
 */

import fetch from "isomorphic-fetch";
import config from "../config";
import {DASHBOARD_PAGE, LOGIN_PAGE,} from "../constants";
import {getAuthHeaders} from "./getAuthHeaders";


export async function checkAuthPermissions() {
  let redirectToPage = LOGIN_PAGE;
  console.log('TEST ', getAuthHeaders)
  return fetch(`${config.apiURL}/dashboard`, {
    headers: {
      ...getAuthHeaders(),
    }
  }).then(response => {
    if (response.status === 200) {
      redirectToPage = DASHBOARD_PAGE;
      // return response.json().then(jsonRes => {
      //   // if response is valid, put user details in the form
      //   if (jsonRes.status === "loggedIn") {
      //     redirectToPage = DASHBOARD_PAGE;
      //     return redirectToPage;
      //   } else if (jsonRes.status === "loggedOut") {
      //     redirectToPage = LOGIN_PAGE;
      //     return redirectToPage;
      //   } else {
      //     return redirectToPage;
      //   }
      // })
    } else {
      return redirectToPage;
    }
  }).catch(err => {
    return redirectToPage;
  });

}

export function checkRegisterAvailable() {
  fetch(`${config.apiURL}/checkRegisterAvailable.php`).then(response => {
    if (response.status === 200) {
      response.json().then(jsonRes => {
        if (jsonRes.RegisterAvailable === "true") {
          this.setState({showRegister: true})
        } else {
          this.setState({showRegister: false})
        }
      })
    } else {
      this.setState({showRegister: false})
    }
  }).catch(err => {
    this.setState({showRegister: false})
  });
}
