/**
 * Created by radughitescu on 2019-08-04
 */

import {AUTH} from "../constants";

const initialState = {
  error: null,
  loading: true
};

export default function login(state = initialState, action) {
  switch (action.type) {
    case AUTH.login:
      return {
        ...state,
        loading: true
      };
    case AUTH.loginSuccess:
      return {
        ...state,
        token: action.payload
      };
    case AUTH.loginFail:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
}
