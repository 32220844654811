/**
 * Created by radughitescu on 13/01/2020
 */
import {createMuiTheme} from "@material-ui/core";

const lightColor = 'rgba(255, 255, 255, 0.7)';

export const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '0'
      }
    },
  }
});

export const styles = theme => ({
  secondaryBar: {
    zIndex: 0
  },
  menuButton: {
    marginLeft: -theme.spacing(1)
  },
  iconButtonAvatar: {
    padding: 4
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white
    }
  },
  button: {
    borderColor: lightColor
  },
  adminButton: {
    cursor: 'pointer'
  }
});
