/**
 * Created by radughitescu on 05.11.2023
 */
import React from "react";
import {CircularProgress} from "@material-ui/core";
import {darkStyles, JsonView} from 'react-json-view-lite';
import {useGetPidDetails} from "./api/pidApi";
import 'react-json-view-lite/dist/index.css';
import './styles.css'

export const RenderPidData = ({agentId, PID}) => {
  const {data, error, isLoading} = useGetPidDetails({agentId, PID});

  if (isLoading) return <CircularProgress size={32} style={{color: '#fff', marginLeft: '50%'}}/>
  if (error) return <div style={{color: '#fff'}}>Could not load PID data</div>

  return <JsonView data={data} style={darkStyles}/>

}
