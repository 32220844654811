/**
 * Created by radughitescu on 20/01/2020
 */

import {checkAuthPermissions} from "../../utils/authChecks";
import {CONFIGURE_OTP_PAGE, FORGOT_PASSWORD_PAGE, LOGIN_PAGE, REGISTER_PAGE} from "../../constants";

export default function checkAuthMiddleware(client, history) {
  return ({dispatch, getState}) => next => action => {
    if (action.type === '@@router/LOCATION_CHANGE') {
      const newAction = action;
      const pathname = newAction.payload.location.pathname;
      checkAuthPermissions().then(hasPermission => {
        if (hasPermission === LOGIN_PAGE) {
          if (!pathname.includes(LOGIN_PAGE) &&
            !pathname.includes(REGISTER_PAGE) &&
            !pathname.includes(FORGOT_PASSWORD_PAGE) &&
            !pathname.includes(CONFIGURE_OTP_PAGE)) {
            newAction.payload.location.pathname = `/${LOGIN_PAGE}`;
            history.push(LOGIN_PAGE);
          }
        }
      });
      return next(newAction);
    }
    return next(action);
  };
}

