/**
 * Created by radughitescu on 2019-07-14
 */

import {ERROR} from "../constants";

const initialState = {
  error: '',
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case ERROR.error:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
