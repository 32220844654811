/**
 * Created by radughitescu on 2019-08-01
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";

class AppContainer extends Component {

  static propTypes = {
    children: PropTypes.node,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    loadingScreen: PropTypes.bool,
    isLoggedIn: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }


  render() {
    const {children} = this.props;
    return <React.Fragment> {children}
    </React.Fragment>
  }
}


export default withRouter(AppContainer);
