const NODE_ENV = process.env.NODE_ENV || 'development';

//http://localhost:8888
//http://192.168.8.106
//http://192.168.126.130
const config = {
  apiURL: NODE_ENV === 'development' ? 'https://dashboard.syntient.dev' : 'https://dashboard.syntient.dev',
  palette: {
    primary: {
      light: '#fff',
      main: '#1A1A22',
      dark: '#0C0C15'
    },
    secondary: {
      light: '#006db3',
      main: '#232f3e',
      dark: '#003f78'
    },
    error: {
      light: '#1de9b6',
    },
    muiDrawer: '#1A1A22',
    muiDivider: '#1A1A22',
    cmd: {
      cmdInputColor: 'white',
      cmdPromptColor: 'red',
      cmdContentColor: 'green',
    }
  },
  title: 'Syntient'
};

export default {
  ...config
};
