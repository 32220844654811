/**
 * Created by radughitescu on 05.03.2021
 */

import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {Typography} from "@material-ui/core";
import config from "../../config";

export default function AlertDialog({
                                      classes,
                                      open,
                                      handleClose,
                                      alertData
                                    }) {

  const getSeverityColor = (severityLevel) => {
    switch (severityLevel) {
      case 'low':
        return '#66B0FF';
      case 'medium':
        return '#800080';
      case 'high':
        return '#FF4545';
      default:
        return '#66B0FF';
    }
  }
  return <Dialog
    fullWidth={true}
    maxWidth='md'
    open={open}
    onClose={handleClose}
    aria-labelledby="max-width-dialog-title"
  >
    <DialogTitle id="max-width-dialog-title"
                 style={{
                   backgroundColor: config.palette.primary.main,
                   color: getSeverityColor(alertData?.severityLevel || 'low')
                 }}>Alert Severity
      Level: {alertData?.severityLevel?.toString()?.toUpperCase()}</DialogTitle>
    <DialogContent style={{backgroundColor: config.palette.primary.main}}>
      <DialogContentText>
        <div className={classes.form}>
          {alertData === null ?
            <Typography variant={"h6"}>No Data</Typography>
            :
            <React.Fragment>
              <Typography style={{color: 'white'}} variant={"h6"}>Computer Name: {alertData.computerName}</Typography>
              <Typography style={{color: 'white'}} variant={"h6"}>PID: {alertData.PID}</Typography>
              <Typography style={{color: 'white'}} variant={"h6"}>Threat Type: {alertData.ThreatType}</Typography>
              {alertData.argumentTrigger && <Typography style={{color: 'white'}} variant={"h6"}>Argument
                Trigger: {alertData.argumentTrigger}</Typography>}
              {alertData.commandLine &&
                <Typography style={{color: 'white'}} variant={"h6"}>Command Line: {alertData.commandLine}</Typography>}
            </React.Fragment>
          }
        </div>
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{backgroundColor: config.palette.primary.main}}>
      <Button onClick={handleClose} style={{color: '#fff'}}>
        Close
      </Button>
    </DialogActions>
  </Dialog>

}
