/**
 * Created by radughitescu on 12.01.2021
 */
import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import AccountCircleSharp from '@material-ui/icons/AccountCircleSharp'
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteIcon from '@material-ui/icons/Delete';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {history} from '../../redux/create';
import Popover from '@material-ui/core/Popover';
import config from '../../config';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import fetch from 'isomorphic-fetch'
import {green, red} from '@material-ui/core/colors';
import Badge from '@material-ui/core/Badge';
import Divider from "@material-ui/core/Divider";
import {MuiThemeProvider} from "@material-ui/core";
import {ADMIN_PROFILE_PAGE, DASHBOARD_PAGE} from "../../constants";
import {styles, theme} from '../styles/header.style';
import ArrowBack from "@material-ui/icons/ArrowBack";
import AlertDialog from "../../components/dialogs/AlertDialog";

function Header(props) {
  const {classes, onDrawerToggle, alerts, getAlerts, tabTitle} = props;
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertDialogState, setAlertDialogState] = React.useState({open: false, alertData: null});

  const open = Boolean(anchorEl);
  const id = 'notifications-center-popover';

  function handleClickP(event) {
    setAnchorEl(event.currentTarget);
    getAlerts();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    history.push(`/${ADMIN_PROFILE_PAGE}`)
  }

  function handleBackPress() {
    history.push(`/${DASHBOARD_PAGE}`);
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function deleteAlert(alert) {
    fetch(`${config.apiURL}/deleteAlert.php`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(alert),
      credentials: 'include'
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        getAlerts();
      });
  }

  function renderAlerts() {
    if (alerts.length === 0)
      return <Typography style={{margin: '10px', width: '350px'}} className={classes.typography}>No
        alerts</Typography>
    else if (alerts.length > 0)
      return <React.Fragment>
        <Box p={2}>
          <Typography variant="h6" style={{fontWeight: '600'}}>
            Notifications Center
          </Typography>
          <Divider light/>
        </Box>
        <List dense={false}>
          {alerts.map((alert, index) => {
            return <ListItem button key={alert.event + index} onClick={() => {
              setAlertDialogState({open: true, alertData: alert})
            }}>
              <ListItemAvatar>
                <NotificationsIcon
                  style={alert.severityLevel === 'extreme' ? {color: red[700]} : {color: green[700]}}/>
              </ListItemAvatar>
              <ListItemText style={{maxWidth: '350px', wordBreak: 'break-word'}}
                            primary={
                              'Detected And Signaled ' +
                              alert.ThreatType +
                              ' PID: ' +
                              alert.PID
                            }
                            secondary='Click to see more'
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={deleteAlert.bind(this, alert)}>
                  <DeleteIcon/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          })}
        </List>
        <AlertDialog classes={classes} alertData={alertDialogState.alertData} handleClose={() => {
          setAlertDialogState({open: false, alertData: null})
        }} open={alertDialogState.open}/>
      </React.Fragment>
  }

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <MuiThemeProvider theme={theme}>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              {renderAlerts()}
            </Popover>
          </MuiThemeProvider>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon/>
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs/>
            <Grid aria-controls={id} aria-haspopup="true" onClick={handleClickP}>
              <Tooltip title={`Alerts • ${alerts.length > 0 ? alerts.length : 'No alerts'}`}>
                <IconButton color="inherit">
                  <Badge badgeContent={alerts.length} color="secondary">
                    <NotificationsIcon/>
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item aria-controls={id} aria-haspopup="true" onClick={handleClick}
                  className={classes.adminButton}>
              <IconButton color="inherit" className={classes.iconButtonAvatar}>
                <AccountCircleSharp/>
              </IconButton>
              Admin Profile
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs style={{display:"flex", alignItems:"center", marginBottom:"20px"}}>
              <IconButton onClick={handleBackPress} style={{marginRight:"20px"}}>
                <ArrowBack className={classes.iconBack} style={{color:"white"}}/>
              </IconButton>
              <br />
              <Typography color="inherit" variant="h5" component="h1">
                {tabTitle}
              </Typography>
            </Grid>
            <br/>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
  tabTitle: PropTypes.string,
  alerts: PropTypes.array,
  getAlerts: PropTypes.func
};

export default withStyles(styles)(Header);
