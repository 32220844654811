/**
 * Created by radughitescu on 05.11.2023
 */
import React from 'react'
import PropTypes from 'prop-types';

import {ThemeProvider} from '@material-ui/styles';
import {Typography, withStyles} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import {withSnackbar} from "notistack";

import {styles, theme} from '../styles/dashboard.style';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {RenderPidData} from "./RenderPidData";
import Header from "../AgentDetailsPage/Header";


class PidDetails extends React.Component {

  constructor(props) {
    super(props)
    this.state = {bottom: {}, alerts: {}, agentId: null, agentData: null, filter: null}
    this.getAlerts = this.getAlerts.bind(this)
  }

  componentDidMount() {
    this.getAlerts();
  }

  getAlerts = (hideSnack) => {
    // const {alerts} = this.state;
    // fetch(`${config.apiURL}/api/fetchAlert`, {
    //   mode: 'cors',
    //   credentials: 'include'
    // })
    //   .then(response => {
    //     return response.json();
    //   })
    //   .then(response => {
    //     response.forEach((alert, index) => {
    //         if (index >= alerts.length && !hideSnack) {
    //           let variant;
    //           switch (alert.event_type) {
    //             case 'agentInstalled':
    //               variant = 'success';
    //               break;
    //             case 'ransomware':
    //               variant = 'error';
    //               break;
    //             default:
    //               variant = 'info';
    //           }
    //           if (this.state.hasDashboardPermission) {
    //             this.props.enqueueSnackbar(alert.event, {
    //               variant: variant,
    //             });
    //           }
    //         }
    //       }
    //     );
    //     if (JSON.stringify(response) !== JSON.stringify(this.state.alerts)) {
    //       this.setState({alerts: response});
    //     }
    //   });
  };

  renderPage = (classes, agentId, pid) => {
    const {bottom, alerts, agentData} = this.state;
    return <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline/>
        <div className={classes.appContent} style={{backgroundColor: '#eaeff1'}}>
          <Header onDrawerToggle={this.handleDrawerToggle}
                  tabTitle={"PID Details"} alerts={alerts}
                  getAlerts={this.getAlerts} classes={classes}/>
          <main className={classes.mainContent}>
            <Box p={5}>
              <Paper>
                <Grid container>
                  <Grid item xs={12} sm={12} style={{padding: '30px'}}>
                    <Typography style={{fontSize: '24px'}}>PID Information:</Typography>
                    <br/>
                    <RenderPidData agentId={agentId} PID={pid}/>
                  </Grid>
                </Grid>
              </Paper>
              <br/>
            </Box>
          </main>
        </div>
      </div>
    </ThemeProvider>
  };

  render() {
    const {classes, match} = this.props;
    const {PID, agentId} = match.params;

    return this.renderPage(classes, agentId, PID);
  }
}

PidDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withSnackbar(PidDetails));
