/**
 * Created by radughitescu on 12/07/2019
 */

import {combineReducers} from 'redux';
import error from './error';
import login from './login';
import {connectRouter} from 'connected-react-router'

export default (history) => combineReducers({
  router: connectRouter(history),
  error,
  login
});
